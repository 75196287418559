import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { FC, ReactNode, useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

const CopyButton: FC<{ copyText: string; children: ReactNode }> = ({ copyText, children }) => {
  const { addToast } = useContext(ToastContext);
  const onCopyText = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS, text: 'Текст успешно скопирован' }));
  };

  return (
    <Stack direction="row" alignItems="center">
      {children}
      <Tooltip title="Скопировать">
        <IconButton
          sx={{ color: 'primary.main', mr: -1.25 }}
          onClick={onCopyText}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default CopyButton;
