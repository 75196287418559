import React, { FC } from 'react';
import Box from '@mui/material/Box';
import {
  Card,
  Chip, Divider, IconButton, Stack, Tooltip, Typography,
} from '@mui/material';
import { formatISODate } from 'helpers/date';
import { Link } from 'react-router-dom';
import CardHeader from 'components/CardHeader';
import { formatNumber, gradeColor } from 'helpers/index';
import { CompetencyMapsQuery } from '@generated/graphql';
import TemplateMark from 'pages/CompetencyMaps/list/components/TemplateMark';
import PlusIcon from 'assets/icons/PlusIcon';
import useCreateTesting from 'hooks/useCreateTesting';

type CardProps = NonNullable<CompetencyMapsQuery['competencyMaps']['data']>[number];

const CompetencyMapCard:FC<CardProps> = (props) => {
  const {
    title, grades, isSample, updatedAt, createdAt, id, blocksCount,
    trialsCount,
  } = props;
  const { createTesting } = useCreateTesting({ competencyMap: props });
  return (
    <Card component={Link} to={`view/${id}`} sx={{ display: 'block', height: '100%', textDecoration: 'none' }}>
      <Stack spacing={2.5} sx={{ height: '100%' }}>
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <CardHeader title={title} />
            <Tooltip title="Создание тестирования по карте компетенций">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  createTesting();
                }}
                sx={{
                  mr: -1, mt: -0.2, width: 32, height: 32,
                }}
              >
                <PlusIcon sx={{ color: 'base.200', width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider sx={{ mt: 1.5 }} />
        </Box>
        <Stack flex={1} spacing={2.5}>
          {
            !!grades?.length
            && (
              <Stack direction="row" spacing={1.5}>
                {grades.map((grade) => (
                  <Chip
                    key={`map-grade-${id}-${grade}`}
                    label={grade}
                    sx={{ backgroundColor: `${gradeColor(grade)}.light`, color: `${gradeColor(grade)}.contrast` }}
                  />
                ))}
              </Stack>
            )
          }
          <Stack>
            <Typography variant="body16rg" sx={{ '& > span': { color: 'primary.main' } }}>
              <span>{blocksCount}</span>
              {' '}
              {formatNumber(['блок знаний', 'блока знаний', 'блоков знаний'], blocksCount, true)}
            </Typography>
            <Typography variant="body16rg" sx={{ '& > span': { color: 'primary.main' } }}>
              <span>{trialsCount}</span>
              {' '}
              {formatNumber([
                'тестирование проведено',
                'тестирования проведено',
                'тестирований проведено'], trialsCount, true)}
            </Typography>
          </Stack>

        </Stack>

        <Box>
          <Divider sx={{ mb: 1.5 }} />
          <Stack direction="row" justifyContent={isSample ? 'space-between' : 'flex-end'}>
            {isSample && <TemplateMark />}
            <Stack>
              <Typography
                variant="body14rg"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > span': { color: 'text.disabled' },
                }}
              >
                <span>созд.</span>
                {' '}
                {formatISODate(createdAt, 'dd.MM.yy')}
              </Typography>
              <Typography
                variant="body14rg"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > span': { color: 'text.disabled' },
                }}
              >
                <span>ред.</span>
                {' '}
                {formatISODate(updatedAt, 'dd.MM.yy')}
              </Typography>
            </Stack>
          </Stack>
        </Box>

      </Stack>
    </Card>
  );
};

export default CompetencyMapCard;
