import { popoverClasses, styled, Popover as MuiPopover } from '@mui/material';

const Popover = styled(MuiPopover)(() => ({
  [`& .${popoverClasses.paper}`]: {
    padding: 20,
    width: 240,
    overflowX: 'unset',
    overflowY: 'unset',
    boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      top: -10,
      left: 50,
      width: 10,
      height: 10,
      backgroundColor: 'background.paper',
      boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
      transform: 'translate(-50%, 50%) rotate(-45deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
}));

export default Popover;
