import {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import cookies from 'js-cookie';
import { Organization_State, Type_Of_Organization } from '@generated/graphql';
import qs from 'qs';
import { DaDataApi } from 'dadata-api';

interface UseDaDataParams {
  status?: Organization_State;
  type?: Type_Of_Organization;
  branch_type?: 'MAIN' | 'BRANCH';
  kpp?: string;
  count?: number;
  query: string;
}

export type DaDataSuggestion = DaDataApi.Suggestion<DaDataApi.Party>;

export interface DaDataResponse {
  suggestions: DaDataSuggestion[]
}

const useDaData = () => {
  const { addToast } = useContext(ToastContext);
  const accessToken = cookies.get('accessToken');
  const [loading, setLoading] = useState(false);

  const getDaData = async (
    params: UseDaDataParams,
    setDaDataResponse: Dispatch<SetStateAction<DaDataResponse['suggestions'] | undefined>>,
  ) => {
    const query = qs.stringify(params);
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_API_URL}/company/dadata?${query}`,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res: DaDataResponse) => {
        setDaDataResponse(res.suggestions);
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
      .finally(() => setLoading(false));
  };

  return { getDaData, loading };
};

export default useDaData;
