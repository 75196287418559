import React, { Fragment } from 'react';
import {
  Grid,
  Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { KnowledgeBlocks } from 'pages/Testing/view/components/ResultTab';
import { Check, Close } from '@mui/icons-material';
import CollapsedBlock from '../CollapsedBlock';

interface AnswerStatisticsProps {
  knowledgeBlocks: KnowledgeBlocks
}

const AnswerStatistics = ({ knowledgeBlocks }:
AnswerStatisticsProps) => (
  <CollapsedBlock title="Результаты тестирования">
    <Grid item xs={12} lg={6}>
      <Table>
        {knowledgeBlocks?.length
          ? knowledgeBlocks.map((block, blockIndex) => (
            <Fragment key={`statistic-block-${block.id}`}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    {`${blockIndex + 1}. ${block.title}`}
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                      block.knowledgeBlockCompetences?.map((knowledgeBlockCompetence) => (
                        <TableBody key={`statistic-knowledgeBlockCompetence-${knowledgeBlockCompetence.id}`}>
                          <TableRow>
                            <TableCell variant="head">
                              {knowledgeBlockCompetence.competence.title}
                            </TableCell>
                            <TableCell colSpan={2} sx={{ textAlign: 'center' }} variant="head">
                              Баллы
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} variant="head">
                              Время
                            </TableCell>
                          </TableRow>
                          {
                            knowledgeBlockCompetence.competence.topics?.map((topic) => {
                              const score = topic.trialAnswer?.score;
                              const time = topic.trialAnswer?.time;
                              return (
                                <TableRow key={`statistic-topic-${topic.id}`}>
                                  <TableCell>
                                    {topic.title}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'center' }}>
                                    {score?.toFixed()}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                    {score && score > 40 ? <Check color="success" /> : <Close color="error" />}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'center' }}>
                                    {time
                                      ? new Date(time * 1000).toISOString().substring(11, 19)
                                      : '00:00:00'}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          }

                        </TableBody>

                      ))
                    }

            </Fragment>
          ))
          : <Typography>Нет ответов на вопросы</Typography>}
      </Table>
    </Grid>
  </CollapsedBlock>

);
export default AnswerStatistics;
