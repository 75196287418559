import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TriangleArrow = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
      <g clipPath="url(#clip0_601_1967)">
        <path
          // eslint-disable-next-line max-len
          d="M6.79519 2.10043C6.89472 1.95825 7.10528 1.95825 7.20481 2.10043L12.2842 9.35664C12.4001 9.52233 12.2816 9.75 12.0793 9.75H1.92066C1.7184 9.75 1.59986 9.52233 1.71585 9.35663L6.79519 2.10043Z"
          stroke="#A9A9A9"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_1967">
          <rect width="14" height="10" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default TriangleArrow;
