import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { formatForFilter } from 'helpers/date';
import { generateOptions } from 'helpers/index';
import { STATUS_TITLE } from 'components/TestingStatus';
import VALIDATION from 'helpers/validation';

const filterFields = [
  {
    name: 'completedAt',
    label: 'Дата завершения тестирования',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value, 'start'),
  },
  {
    name: 'competencyMapTitle',
    label: 'Карта компетенций',
    inputType: InputTypes.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },
  {
    name: 'candidateFullName',
    label: 'Кандидат',
    inputType: InputTypes.TEXT,
    rules: VALIDATION.userName,
  },
  {
    name: 'status',
    label: 'Статус',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: generateOptions(STATUS_TITLE),
    customFilter: (value: any) => value.id,
  },

];

export default filterFields;
