import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TemplateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M2.10944 1C1.96252 1.00074 1.81721 1.03051 1.68184 1.08759C1.54646 1.14466 1.4237 1.22793 1.32061 1.3326C1.21752 1.43727 1.13614 1.56128 1.08112 1.69751C1.02611 1.83373 0.998562 1.97949 1.00006 2.12639V15.8736C1.00006 16.5048 1.4971 17 2.10944 17H11.8878C12.0348 16.999 12.1801 16.9691 12.3154 16.9119C12.4508 16.8548 12.5736 16.7716 12.6768 16.667C12.78 16.5624 12.8615 16.4385 12.9168 16.3023C12.9722 16.1662 13.0001 16.0205 12.9991 15.8736V4.49634C12.9982 4.36432 12.9452 4.23798 12.8517 4.14482L9.87319 1.16632C9.8265 1.11375 9.76915 1.07173 9.70495 1.04306C9.64076 1.01438 9.57119 0.999704 9.50088 1H2.10944ZM9.00195 2.00166L8.99817 4.50012C8.99817 4.77605 9.22307 5.00095 9.49899 5.00095H12.0012V15.8755C12.0012 15.9473 11.954 16.0002 11.8897 16.0002H2.10944C2.04518 16.0002 1.99982 15.9492 1.99982 15.8755V2.12639C1.99982 2.0508 2.04518 1.99977 2.10944 1.99977L9.00195 2.00166ZM9.99982 2.7066L11.2944 4.00118H9.99793L9.99982 2.7066ZM3.99935 6.49965C3.33221 6.49965 3.33221 7.5013 3.99935 7.5013H9.99793C10.6651 7.5013 10.6651 6.49965 9.99793 6.49965H3.99935ZM3.99935 8.99811C3.33221 8.99811 3.33221 9.99977 3.99935 9.99977H9.99793C10.6651 9.99977 10.6651 8.99811 9.99793 8.99811H3.99935ZM3.99935 12.502H9.99793C10.6651 12.502 10.6651 11.5004 9.99793 11.5004H3.99935C3.33221 11.5004 3.33221 12.502 3.99935 12.502Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  </SvgIcon>
);

export default TemplateIcon;
