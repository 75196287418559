import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M18.5 7.24999C18.5 7.05108 18.421 6.86031 18.2803 6.71966C18.1397 6.57901 17.9489 6.49999 17.75 6.49999C17.5511 6.49999 17.3603 6.57901 17.2197 6.71966C17.079 6.86031 17 7.05108 17 7.24999V16.94L14.78 14.72C14.7113 14.6463 14.6285 14.5872 14.5365 14.5462C14.4445 14.5052 14.3452 14.4832 14.2445 14.4814C14.1438 14.4796 14.0438 14.4981 13.9504 14.5359C13.857 14.5736 13.7722 14.6297 13.701 14.701C13.6297 14.7722 13.5736 14.857 13.5359 14.9504C13.4982 15.0438 13.4796 15.1438 13.4814 15.2445C13.4832 15.3452 13.5052 15.4445 13.5462 15.5365C13.5872 15.6285 13.6463 15.7113 13.72 15.78L17.22 19.28C17.3606 19.4204 17.5512 19.4993 17.75 19.4993C17.9488 19.4993 18.1394 19.4204 18.28 19.28L21.78 15.78C21.9125 15.6378 21.9846 15.4498 21.9812 15.2555C21.9777 15.0612 21.899 14.8758 21.7616 14.7384C21.6242 14.601 21.4388 14.5222 21.2445 14.5188C21.0502 14.5154 20.8622 14.5875 20.72 14.72L18.5 16.94V7.24999ZM2.75 6.99999C2.55109 6.99999 2.36032 7.07901 2.21967 7.21966C2.07902 7.36031 2 7.55108 2 7.74999C2 7.9489 2.07902 8.13967 2.21967 8.28032C2.36032 8.42097 2.55109 8.49999 2.75 8.49999H12.25C12.4489 8.49999 12.6397 8.42097 12.7803 8.28032C12.921 8.13967 13 7.9489 13 7.74999C13 7.55108 12.921 7.36031 12.7803 7.21966C12.6397 7.07901 12.4489 6.99999 12.25 6.99999H2.75ZM2.75 12C2.55109 12 2.36032 12.079 2.21967 12.2197C2.07902 12.3603 2 12.5511 2 12.75C2 12.9489 2.07902 13.1397 2.21967 13.2803C2.36032 13.421 2.55109 13.5 2.75 13.5H8.25C8.44891 13.5 8.63968 13.421 8.78033 13.2803C8.92098 13.1397 9 12.9489 9 12.75C9 12.5511 8.92098 12.3603 8.78033 12.2197C8.63968 12.079 8.44891 12 8.25 12H2.75ZM2.75 17C2.55109 17 2.36032 17.079 2.21967 17.2197C2.07902 17.3603 2 17.5511 2 17.75C2 17.9489 2.07902 18.1397 2.21967 18.2803C2.36032 18.421 2.55109 18.5 2.75 18.5H6.25C6.44891 18.5 6.63968 18.421 6.78033 18.2803C6.92098 18.1397 7 17.9489 7 17.75C7 17.5511 6.92098 17.3603 6.78033 17.2197C6.63968 17.079 6.44891 17 6.25 17H2.75Z"
        fill="currentColor"
      />
    </svg>

  </SvgIcon>
);

export default CalendarIcon;
