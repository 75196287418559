import { KnowledgeBlocks } from 'pages/Testing/view/components/ResultTab';

type ExpandedTrialAnswer = {
  blockTitle: string;
  competenceTitle: string;
  topicTitle: string;
  score?: number | null;
  time?: number | null;
  questionText: string;
  candidateAnswer: string;
  criterionAssessments: Array<{
    criteria: string;
    decision: string;
  }> | null | undefined;
};

const getTrialAnswerList = (knowledgeBlocks: KnowledgeBlocks): Array<ExpandedTrialAnswer> => {
  const result: Array<ExpandedTrialAnswer> = [];

  if (!knowledgeBlocks) return result;

  knowledgeBlocks.forEach((block) => {
    const blockTitle = block.title;

    (block.knowledgeBlockCompetences || []).forEach((competenceBlock) => {
      const competenceTitle = competenceBlock.competence.title;

      (competenceBlock.competence.topics || []).forEach((topic) => {
        const topicTitle = topic.title;

        const { trialAnswer } = topic;
        if (trialAnswer) {
          result.push({
            blockTitle,
            competenceTitle,
            topicTitle,
            score: trialAnswer.score,
            time: trialAnswer.time,
            questionText: trialAnswer.question.text,
            criterionAssessments: trialAnswer.criterionAssessments,
            candidateAnswer: trialAnswer.answer,
          });
        }
      });
    });
  });

  return result;
};

export default getTrialAnswerList;
