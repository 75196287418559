import React from 'react';
import TextBlock from 'pages/Testing/view/components/ResultTab/components/TextBlock';

const CommonInfo = ({ score }: { score: number }) => {
  const descriptionText = () => {
    if (score >= 0 && score <= 25) {
      return `Кандидат продемонстрировал низкий уровень знаний, предоставив корректные ответы на меньше
       чем четверть предложенных тем. Кандидату необходимо повторить темы, в которых он не продемонстрировал
        уверенных знаний, и пройти повторное тестирование.
Уровень знаний кандидата (${+score}%) является слишком низким для прохождения на следующий этап собеседования.`;
    }
    if (score > 25 && score <= 50) {
      return `Кандидат продемонстрировал довольно низкий уровень знаний, предоставив корректные ответы на меньше 
      чем половину предложенных тем. Кандидату необходимо повторить темы, в которых он не продемонстрировал 
      уверенных знаний, и пройти повторное тестирование.
Уровень знаний кандидата (${+score}%) является достаточно низким для прохождения на следующий этап собеседования.`;
    }
    if (score > 50 && score <= 75) {
      return `Кандидат продемонстрировал достаточный уровень знаний, 
      предоставив корректные ответы на больше чем половину предложенных тем. 
      По темам, в которых кандидат не продемонстрировал уверенных знаний,
       возможно, потребуется повторное тестирование.
Уровень знаний кандидата (${+score}%) является неплохим, но для прохождения на следующий этап 
собеседования, возможно, потребуется дополнительное обсуждение.`;
    }
    return `Кандидат продемонстрировал хороший уровень знаний, предоставив корректные ответы 
    на большинство предложенных тем. Темы, в которых кандидат не продемонстрировал уверенных знаний, 
    не являются критичными для данной позиции.
Уровень знаний кандидата (${+score}%) является достаточным для прохождения на следующий этап собеседования`;
  };

  const text = descriptionText();
  return <TextBlock title="Общий вывод по тестированию" text={text} />;
};

export default CommonInfo;
