import { INPUT_DEFAULT_VALUE, InputTypes } from 'components/generators/inputsGenerator';
import qs from 'qs';
import { format, isValid } from 'date-fns';
import _ from 'lodash';
import { cleanSpaceInString } from 'helpers/validation/createValidation';

export const FILTER_TYPES = {
  CUSTOM_FILTER: 'CustomFilter',
};

export const generateQueryOnSubmit = (data: any, filters: any, setSearchParams: any) => {
  const params = {};
  filters.forEach((filter: any) => {
    const { inputType, name } = filter;
    const filterValue = _.get(data, name);
    const setParam = (newData: string | object) => _.set(params, name, newData);
    if (!filterValue) return;
    switch (inputType) {
      case InputTypes.DATE: {
        if (isValid(filterValue)) setParam(format(filterValue, 'yyyy-MM-dd'));
        else setParam(filterValue);
        break;
      }
      default: {
        setParam(typeof filterValue === 'string' ? cleanSpaceInString(filterValue) : filterValue);
        break;
      }
    }
  });
  setSearchParams(qs.stringify(params));
};

export const onClearFilter = (filters: any, reset: any, setSearchParams: any) => {
  setSearchParams({});
  const params = {};
  filters.forEach((filter: any) => {
    const { name, defaultValue, multiple } = filter;
    const inputType = filter.inputType as InputTypes;
    _.set(params, name, multiple ? [] : defaultValue || INPUT_DEFAULT_VALUE[inputType]);
  });
  reset(params);
};

export const onUseEffect = (filters: any, reset: any, query: any) => {
  const params = {};
  filters.forEach((filter: any) => {
    const { name } = filter;
    const inputType = filter.inputType as InputTypes;

    if (_.get(query, filter.name)) {
      const dataName = _.get(query, name);
      const setParam = (newData: any) => _.set(params, name, newData);
      setParam(dataName || INPUT_DEFAULT_VALUE[inputType]);
    } else _.set(params, name, INPUT_DEFAULT_VALUE[inputType]);
  });
  if (Object.keys(params).length) {
    reset(params);
  }
};

export const generateFilterQuery = (filters: any, query: any) => {
  const params: any = {};
  filters.forEach((filter: any) => {
    if (_.get(query, filter.name)) {
      const filterName = filter.filterName || filter.name;
      const queryValue = _.get(query, filter.name);
      const setParam = (newData: any) => _.set(params, filterName, newData);
      switch (filter.filterType) {
        case FILTER_TYPES.CUSTOM_FILTER: {
          setParam(filter.customFilter(queryValue));
          break;
        }
        default:
          setParam(queryValue);
          break;
      }
    }
  });

  return Object.keys(params).length === 0 ? undefined : params;
};
