import PATTERN from './patterns';
import { createValidation } from './createValidation';

const VALIDATION = {
  userName: createValidation({
    pattern: PATTERN.userName,
    maxLength: 155,
  }),
  fullUserName: createValidation({
    pattern: PATTERN.fullUserName,
    maxLength: 155,
  }),
  email: createValidation({
    pattern: PATTERN.email,
    maxLength: 256,
  }),
  emailFull: createValidation({
    pattern: PATTERN.emailFull,
    maxLength: 256,
  }),
  telegramUsername: createValidation({
    pattern: PATTERN.telegramUsername,
    maxLength: 32,
  }),
  inn: createValidation({
    pattern: PATTERN.inn,
    maxLength: 12,
  }),
  companyName: createValidation({
    pattern: PATTERN.companyName,
    maxLength: 256,
  }),
  position: createValidation({
    pattern: PATTERN.position,
    maxLength: 256,
  }),
  address: createValidation({
    pattern: PATTERN.address,
    maxLength: 256,
  }),
  competencyMapTitle: createValidation({
    pattern: PATTERN.competencyMapTitle,
    maxLength: 256,
  }),
};

export default VALIDATION;
