import React from 'react';
import { KnowledgeBlocks } from 'pages/Testing/view/components/ResultTab';
import { Typography, Grid } from '@mui/material';
import getLowScoreTopics from 'pages/Testing/view/components/ResultTab/helpers/getRecommendedThemes';
import CollapsedBlock from '../CollapsedBlock';

export enum Decision {
  correct = 'correct',
  almost_correct = 'almost_correct',
  partial_correct = 'partial_correct',
  partial_incorrect = 'partial_incorrect',
  incorrect = 'incorrect',
}

export type RecommendationBlockProps = {
  knowledgeBlocks: KnowledgeBlocks
};

const RecommendationBlock = ({ knowledgeBlocks }:
RecommendationBlockProps) => {
  const lowScoreTopics = getLowScoreTopics(knowledgeBlocks);

  return (
    <CollapsedBlock title="Темы, рекомендованные к повторению">
      <Grid item xs={12} lg={6}>
        {lowScoreTopics?.length ? (
          <ul>
            {
              lowScoreTopics?.map((block) => (
                <li key={`recommendation-block-${block.id}`}>
                  {block.title}
                  <ul>
                    {
                    block.competences.map((competence) => (
                      <li key={`recommendation-block-${block.id}-competence-${competence.id}`}>
                        <Typography variant="body14rg">
                          {competence.title}
                        </Typography>
                        <ul>
                          {
                            competence.topics.map((topic) => (
                              <li
                                key={`recommendation-block-${block.id}-competence-${competence.id}-topic-${topic.id}`}
                              >
                                <Typography variant="body14rg">
                                  {topic.title}
                                </Typography>
                              </li>
                            ))
                          }
                        </ul>
                      </li>
                    ))
                  }
                  </ul>
                </li>
              ))
        }

          </ul>
        ) : (
          <Typography variant="body14rg">
            Кандидат набрал по всем темам более 40 баллов
          </Typography>
        )}
      </Grid>
    </CollapsedBlock>

  );
};

export default RecommendationBlock;
