import { KnowledgeBlocks } from 'pages/Testing/view/components/ResultTab';

type FormattedTopic = {
  id: string;
  title: string;
  score: number | null | undefined;
};

type FormattedCompetence = {
  id: string;
  title: string;
  topics: FormattedTopic[];
};

type FormattedKnowledgeBlock = {
  id: string;
  title: string;
  competences: FormattedCompetence[];
};

const getRecommendedThemes = (knowledgeBlocks: KnowledgeBlocks): Array<FormattedKnowledgeBlock> => {
  if (!knowledgeBlocks) return [];
  return knowledgeBlocks.map((block) => {
    const competences = (block.knowledgeBlockCompetences || []).map((competenceBlock) => {
      const topics = (competenceBlock.competence.topics || [])
        .filter((topic) => !topic.trialAnswer?.score || topic.trialAnswer.score < 40)
        .map((topic) => ({
          id: topic.id,
          title: topic.title,
          score: topic.trialAnswer?.score,
        }));

      return {
        id: competenceBlock.competence.id,
        title: competenceBlock.competence.title,
        topics,
      };
    }).filter((competence) => competence.topics.length > 0);

    return {
      id: block.id,
      title: block.title,
      competences,
    };
  }).filter((block) => block.competences.length > 0);
};

export default getRecommendedThemes;
