import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const CardHeader = ({ title }: { title: string }) => (
  <Tooltip title={title}>
    <Typography
      variant="h20"
      sx={{
        display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', height: 30, whiteSpace: 'nowrap',
      }}
    >
      {title}
    </Typography>
  </Tooltip>
);

export default CardHeader;
