import React, { useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Exact, TopicQuery } from '@generated/graphql';
import { QueryResult } from '@apollo/client';
import QuestionItem from './QuestionItem';

interface QuestionListProps {
  isConfirmed?: boolean;
  topicQuery: QueryResult<TopicQuery, Exact<{ topicId: string }>>;
}

const QuestionList = ({ isConfirmed, topicQuery }:QuestionListProps) => {
  const { data } = topicQuery;
  const topic = data?.topic;

  const questions = topic?.questions;
  const list = useMemo(
    () => questions
      ?.filter((question) => (isConfirmed ? question.confirmation : !question.confirmation)),
    [isConfirmed, questions],
  );

  return (
    <Box>
      {list?.length ? list.map((item, index) => (
        <>
          <QuestionItem question={item} topicQuery={topicQuery} />
          {list.length > index + 1 && <Divider />}
        </>
      )) : (
        <Typography
          variant="body16rg"
          textAlign="center"
          sx={{ display: 'block', py: 2.5 }}
        >
          Вопросов нет
        </Typography>
      )}
    </Box>
  );
};

export default QuestionList;

QuestionList.defaultProps = {
  isConfirmed: false,
};
