import { Components, Theme } from '@mui/material';

export default {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        body14rg: 'p',
        h20: 'h4',
        h28: 'h2',
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      text: ({ theme }) => ({
        textTransform: 'none',
        '&:hover, &:active': {
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      }),
      textSizeSmall: ({ theme }) => ({
        ...theme.typography.body14rg,
      }),
      textPrimary: ({ theme }) => ({
        color: theme.palette.primary.main,
        '&:hover, &:active': {
          color: theme.palette.primary.dark,
        },
      }),
      textSizeMedium: ({ theme }) => ({
        ...theme.typography.body16rg,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10,
      }),
      textSecondary: ({ theme }) => ({
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.primary.main,
        },
        '&:active': {
          color: theme.palette.primary.dark,
        },
      }),
      contained: ({ theme }) => ({
        ...theme.typography.body16rg,
        textTransform: 'none',
      }),
      containedSizeMedium: {
        borderRadius: 8,
        paddingBottom: 8,
        paddingTop: 8,
        paddingRight: 16,
        paddingLeft: 16,
      },
      containedSizeSmall: {
        borderRadius: 6,
        paddingBottom: 5,
        paddingTop: 5,
        paddingRight: 12,
        paddingLeft: 12,
      },
      containedPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:action': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.base[100],
        },
      }),
      containedSizeLarge: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 16,
        paddingLeft: 16,
        borderRadius: 8,
      },
      containedSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.bgSwitch,
        color: theme.palette.text.primary,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.primary.main,
          boxShadow: 'none',
        },
        '&:action': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.primary.main,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.border,
          boxShadow: 'none',
        },
      }),
      outlined: ({ theme }) => ({
        ...theme.typography.body16rg,
        textTransform: 'none',
      }),
      outlinedSizeMedium: {
        borderRadius: 8,
        paddingBottom: 8,
        paddingTop: 8,
        paddingRight: 16,
        paddingLeft: 16,
      },
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.text.primary,
        borderColor: theme.palette.base[200],
        '&:hover': {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },
        '&:action': {
          color: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          borderColor: theme.palette.base[200],
          backgroundColor: 'transparent',
        },
      }),
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
          paddingRight: 70,
          paddingLeft: 70,
        },
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiAppBar-root': {
          backgroundColor: '#fff',
          color: theme.palette.text.primary,
        },
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.bgSwitch,
        borderRadius: 8,
        '&.MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.blue,
            borderWidth: 1,
          },
          '&.Mui-error': {
            color: theme.palette.error.contrastText,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
              borderWidth: 1,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              borderWidth: 1,
            },

          },
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'transparent',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        position: 'relative',
        '& .MuiFormHelperText-root': {
          ...theme.typography.body14rg,
          // position: 'absolute',
          // top: 42,
          marginLeft: 0,
          '&.Mui-error': {
            color: theme.palette.error.contrastText,
          },
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
      popper: {
        '& .MuiAutocomplete-paper': {
          borderRadius: '0px 0px 8px 8px',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.10)',
        },
      },
      option: ({ theme }) => ({
        ...theme.typography.body14rg,
        color: theme.palette.text.primary,
        paddingTop: 6,
        paddingBottom: 6,
      }),
      listbox: {
        padding: 0,
      },
      tagSizeSmall: ({ theme }) => ({
        '& .MuiChip-label': {
          ...theme.typography.body14rg,
          paddingLeft: 8,
          paddingRight: 8,
        },
      }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.border,
        borderColor: theme.palette.border,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        height: 'auto',
      },
      labelMedium: ({ theme }) => ({ ...theme.typography.body16rg }),
      label: { padding: 0 },
      sizeMedium: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 14,
        paddingLeft: 14,
      },
      sizeSmall: {
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 8,
        paddingBottom: 8,
      },
      labelSmall: ({ theme }) => ({
        ...theme.typography.body14rg,
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        width: 32,
        height: 32,
      },
      previousNext: ({ theme }) => ({
        backgroundColor: 'white',
        color: theme.palette.base[300],
        boxShadow: '0px 0px 9.733px rgba(164, 184, 253, 0.24)',
        borderColor: 'transparent',
      }),
      page: ({ theme }) => ({
        borderColor: 'transparent',
        ...theme.typography.body14rg,
        color: theme.palette.base[500],
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
      icon: ({ theme }) => ({
        color: theme.palette.base[300],
      }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&.MuiMenu-list': {
          paddingTop: 0,
          paddingBottom: 10,
        },
      },
    },
  },
  MuiMenuItem: {
    variants: [
      {
        props: { textColor: 'primary' },
        style: ({ theme }) => ({
          ...theme.typography.body14rg,
          color: theme.palette.primary.main,
          '&.Mui-disabled': {
            opacity: 1,
          },
        }),
      },
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body16rg,
        color: theme.palette.text.primary,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 8,
        paddingLeft: 8,
        '&:hover': {
          backgroundColor: theme.palette.bgSwitch,
        },
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderRadius: 8,
        boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
        padding: 20,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.10)',
        clipPath: 'inset(0px -10px -10px -10px)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        paddingTop: 20,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 12,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingTop: 0,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        marginTop: 24,
        padding: 0,
        '& > :not(style) ~ :not(style)': {
          marginLeft: 16,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        borderRadius: '8px 8px 0px 0px',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 16,
        ...theme.typography.subtitle18rg,
        textTransform: 'none',
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        display: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.divider,
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        marginRight: 4,
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTypography-root': {
          ...theme.typography.body14rg,
          color: theme.palette.text.secondary,
        },
        '&.Mui-disabled': {
          '& .MuiTypography-root': {
            color: theme.palette.text.disabled,
          },
          '& .MuiCheckbox-root .MuiSvgIcon-root': {
            color: theme.palette.text.disabled,
          },

        },

      }),
    },
  },
  MuiAlert: {
    defaultProps: {
      severity: 'info',
      variant: 'filled',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body14rg,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 8,
        paddingLeft: 8,

      }),
      icon: {
        padding: 0,
      },
      message: {
        padding: 0,
      },
      filledInfo: ({ theme }) => ({
        backgroundColor: theme.palette.bgSwitch,
        color: theme.palette.text.primary,
        '& .MuiAlert-icon': {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        padding: 12,
        backgroundColor: '#fff',
        color: theme.palette.text.primary,
        boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
        ...theme.typography.describe2,
      }),
      arrow: {
        color: '#fff',
        '&:before': {
          boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.base[100],
        color: theme.palette.text.secondary,
        fontSize: 16,
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        transition: theme.transitions.create(['fill', 'color'], {
          duration: theme.transitions.duration.shorter,
        }),
      }),
    },
  },
} as Components<Theme>;
